import { useMemo } from 'react';

import type { Tracker } from '../../tracking/core/types/tracker';

import { useCustomerio } from './useCustomerio';

export function useCustomerioTracker() {
  const { track, identify, page } = useCustomerio();

  const tracker: Tracker = useMemo(
    () => ({
      track(trackingItem) {
        switch (trackingItem.type) {
          case 'event':
            track(trackingItem.name, trackingItem.data ?? {});
            break;
          case 'identify': {
            const user = trackingItem.user;
            identify(user.id, { first_name: user.firstName, last_name: user.lastName, email: user.email });
            break;
          }
          case 'pageView':
            page(trackingItem.path);
            break;
          default:
            break;
        }
      },
    }),
    [identify, page, track],
  );

  return {
    tracker,
  } as const;
}
