import type { Config } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import { type ProviderProps, useMemo } from 'react';

import { type MixpanelContext, MixpanelReactContext } from '../context';

export interface MixpanelProviderProps extends Omit<ProviderProps<MixpanelContext>, 'value'> {
  config?: Partial<Config>;
  name?: string;
  token?: string;
}

export function MixpanelProvider({ children, config: _config, name: _name, token }: MixpanelProviderProps) {
  const name = useMemo(() => _name ?? 'react-mixpanel-browser', [_name]);

  const config = useMemo(
    () => ({
      ..._config,
    }),
    [_config],
  );

  const context = useMemo(() => (token ? mixpanel.init(token, config, name) : undefined), [config, name, token]);

  return <MixpanelReactContext.Provider value={context}>{children}</MixpanelReactContext.Provider>;
}
