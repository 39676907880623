import { useCallback } from 'react';

import { useLogger } from '../../logging/hook/useLogger';
import { useTutorial } from '../../tutorials/hooks/useTutorial';
import type { CustomerioMessageAction } from '../types/customerioMessageAction';

export function useCustomerIoMessageAction() {
  const { logger } = useLogger();
  const { start } = useTutorial();
  const execute = useCallback(
    (action: CustomerioMessageAction) => {
      switch (action.type) {
        case 'show-tutorial': {
          logger.debug(`Show tutorial ${action.tutorialId}`);
          start({ tutorialId: action.tutorialId });
          break;
        }
        case 'unknown': {
          break;
        }
      }
    },
    [logger, start],
  );

  return {
    execute,
  } as const;
}
