import { useMemo } from 'react';

import type { Tracker } from '../../tracking/core/types/tracker';

import { useLogger } from './useLogger';

export function useLoggerTracker() {
  const { logger } = useLogger();

  const tracker: Tracker = useMemo(
    () => ({
      track(trackingItem) {
        switch (trackingItem.type) {
          case 'event':
            logger.info('Track event:', trackingItem.name, trackingItem.data);
            break;
          case 'identify':
            logger.info('Track identify:', trackingItem.user);
            break;
          case 'pageView':
            logger.info('Track page view:', trackingItem.path, trackingItem.name);
            break;
        }
      },
    }),
    [logger],
  );

  return {
    tracker,
  } as const;
}
