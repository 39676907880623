/* eslint-disable prefer-const */
import { useEffect, useMemo } from 'react';

import { getPublicEnv } from '../../../environment/getPublicEnv';
import { useLogger } from '../../logging/hook/useLogger';
import type { Tracker } from '../../tracking/core/types/tracker';

export function useGoogleTracker() {
  const { logger } = useLogger();

  useEffect(() => {
    const destinationId = getPublicEnv('PUBLIC_GOOGLE_DESTINATION_ID');
    if (!destinationId || destinationId === 'null') {
      logger.info(`Skipping Google tracking initialization (destination id: ${destinationId})`);
      return;
    }

    if (window.dataLayer != null) {
      return;
    }

    logger.info(`Initializing GoogleAnalytics tracker with id: ${destinationId}`);
    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        // eslint-disable-next-line
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode?.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', destinationId);
  }, [logger]);

  const tracker: Tracker = useMemo(
    () => ({
      track(trackingItem) {
        switch (trackingItem.type) {
          case 'event':
            window.dataLayer?.push({ event: trackingItem.name, ...(trackingItem.data ?? {}) });
            break;
          default:
            break;
        }
      },
    }),
    [],
  );

  return {
    tracker,
  } as const;
}
