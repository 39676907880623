import { QueryClient, QueryClientProvider as RQQueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';

import { ApiError } from '../api/ApiError';

export function QueryClientProvider({
  children,
  refetchOnWindowFocus,
}: {
  children: React.ReactNode;
  refetchOnWindowFocus?: boolean;
}) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus,
            // Prevent refetching too often, e.g. between redirections
            // Also, with SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 5 * 1000, // 5 seconds
            retry(failureCount, error) {
              // Prevent retry on client side errors
              if (error && error instanceof ApiError && error.status < 500) {
                return false;
              }
              return failureCount < 3;
            },
          },
        },
      }),
  );

  return <RQQueryClientProvider client={queryClient}>{children}</RQQueryClientProvider>;
}
