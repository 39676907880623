import { TutorialsIdsSchema } from '../../tutorials/constants/tutorials';
import type { CustomerioMessageAction } from '../types/customerioMessageAction';
import type { RawCustomerioMessageAction } from '../types/rawCustomerioMessageAction';

export function serializeRawCustomerioMessageAction(
  customerioMessageAction: RawCustomerioMessageAction,
): CustomerioMessageAction | null {
  if (!customerioMessageAction.detail.actionName) {
    return null;
  }

  switch (customerioMessageAction.detail.actionName) {
    case 'show-tutorial': {
      const tutorialId = TutorialsIdsSchema.safeParse(customerioMessageAction.detail.actionValue);

      if (tutorialId.success) {
        return {
          type: 'show-tutorial',
          tutorialId: tutorialId.data,
        };
      } else {
        return {
          type: 'unknown',
        };
      }
    }
    default:
      return {
        type: 'unknown',
      };
  }
}
