import type { PublicEnv } from './publicEnvSchema';

export function PublicEnv(props: { env: PublicEnv }) {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(props.env)}`,
      }}
    />
  );
}
