import { useMemo } from 'react';

import { getPublicEnv } from '../../../environment/getPublicEnv';
import type { Logger } from '../../logging/types/logger';

export function useConsoleLogger() {
  const areConsoleLogsEnabled = getPublicEnv('PUBLIC_ENABLE_CONSOLE_LOGS') === 'true';

  const logger: Logger = useMemo<Logger>(
    () =>
      areConsoleLogsEnabled
        ? {
            debug: (message, ...optionalParams) => console.log(message, ...optionalParams),
            info: (message, ...optionalParams) => console.log(message, ...optionalParams),
            warn: (message, ...optionalParams) => console.log(message, ...optionalParams),
            error: (message, ...optionalParams) => console.log(message, ...optionalParams),
          }
        : {
            debug: () => {},
            info: () => {},
            warn: () => {},
            error: () => {},
          },
    [areConsoleLogsEnabled],
  );

  return {
    logger: logger,
  } as const;
}
