import { useMemo } from 'react';

import type { Tracker } from '../../tracking/core/types/tracker';

import { useMixpanel } from './useMixPanel';

export function useMixpanelTracker() {
  const mixpanel = useMixpanel();

  const tracker: Tracker = useMemo(
    () => ({
      track(trackingItem) {
        switch (trackingItem.type) {
          case 'event':
            mixpanel?.track(trackingItem.name, trackingItem.data);
            break;
          case 'identify': {
            const user = trackingItem.user;
            mixpanel?.identify(user.id);
            mixpanel?.register({ first_name: user.firstName, last_name: user.lastName, email: user.email });
            break;
          }
          case 'pageView':
            mixpanel?.track_pageview({ page: trackingItem.path });
            break;

          default:
            break;
        }
      },
    }),
    [mixpanel],
  );

  return {
    tracker,
  } as const;
}
